<template>
	<div>
		<el-form :inline="true" :model="params" :size="theme.size" v-if="!theme.is_mobile">
			<el-form-item label="手机号码">
				<el-input v-model="params.keywords" placeholder="手机号码" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getShortMessages({ ...params, page: 1})">查询</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="records" :max-height="theme.height - 320" :size="theme.size">
			<el-table-column prop="mobile" label="号码"></el-table-column>
			<el-table-column prop="type" label="类型"></el-table-column>
			<el-table-column prop="code" label="验证码"></el-table-column>
			<el-table-column prop="status" label="当前状态"></el-table-column>
			<el-table-column prop="platform" label="发送平台"></el-table-column>
			<el-table-column prop="updated_at" label="更新时间" width="210"></el-table-column>
			<el-table-column prop="created_at" label="创建时间" width="210"></el-table-column>
		</el-table>
		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getShortMessages({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getShortMessages({ ...params, page: v }) }"
			background></el-pagination>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			async getShortMessages (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_SHORT_MESSAGES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.records = result.data;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				records: [],
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getShortMessages(this.params, true);
		}
	};
</script>